'use client'
import React from 'react'

interface IProps {
  title?: string
  description?: string
}

const Hero = (props: IProps) => {
  const { title, description } = props
  return (
    <section id="home" className="top-0 h-screen w-full bg-hero bg-cover bg-no-repeat">
      <header className="flex !min-h-[49rem] px-8">
        <div className="mx-0 md:mx-20 mt-20 md:mt-32 h-full w-full md:w-2/3 place-items-center">
          <div className="max-w-xl font-medium text-primary-100 text-3xl bg-primary-950 bg-opacity-60 py-5 px-10 rounded text-center md:text-left">
            {description || 'El incalculable valor del cuidado en la infancia'}
          </div>
        </div>
      </header>
    </section>
  )
}

export default Hero
